<template>
  <div v-if="total !== ''" :class="resumeClass">
    <div class="row">
      <p class="sm-text">{{ translateString("total") }}</p>
      <p v-if="total > 0" class="sm-text-highlight">
        {{ formatPrice(total, 2, "€") }}
      </p>
    </div>
    <p v-if="totalDisclaimer != ''" class="disclaimer">
      {{ totalDisclaimer }}
    </p>
  </div>
</template>

<script>
import { formatPrice, translateString } from "@/functions/functions.js";

export default {
  name: "ServicesTotal",
  props: {
    screen: { type: String, default: "" },
  },
  data() {
    return {
      resumeClass: "",
    };
  },
  computed: {
    total() {
      return this.$store.state.updatableServicesTotal;
    },
    totalDisclaimer() {
      let disclaimer = "";

      if (this.$store.state.totalDisclaimer.length > 0) {
        disclaimer += "* ";
        this.$store.state.totalDisclaimer.forEach((el) => {
          disclaimer += el + ", ";
        });

        disclaimer = disclaimer.slice(0, -2);

        disclaimer += " " + translateString("notIncludedInPrice");
      }

      return disclaimer;
    },
  },
  created() {
    if (this.screen == "mobile") {
      this.resumeClass = "services-total-mobile elm-mobile ";
    } else if (this.screen == "desktop") {
      this.resumeClass = "services-total-desktop elm-desktop ";
    }
  },
  methods: {
    formatPrice,
    translateString,
  },
};
</script>

<style lang="scss" scoped>
@import "/UpdatedResume";
</style>
