<template>
  <div class="sidebar elm-desktop">
    <CarInfo />
    <UpdatedResume
      v-if="desktopResume"
      screen="desktop"
      :dealer="dealer"
      :services="services"
      :mobility="mobility"
      :wash="wash"
      :schedule="schedule"
    />
    <ServicesTotal v-if="servicesScreen" screen="desktop" />
  </div>
</template>

<script>
import CarInfo from "@/components/UpdatedResume/CarInfo.vue";
import ServicesTotal from "@/components/UpdatedResume/ServicesTotal.vue";
import UpdatedResume from "@/components/UpdatedResume/UpdatedResume.vue";

export default {
  name: "Sidebar",
  components: {
    CarInfo,
    ServicesTotal,
    UpdatedResume,
  },
  props: {
    desktopResume: { type: Boolean, default: false },
    servicesScreen: { type: Boolean, default: false },
    dealer: { type: Boolean, default: false },
    services: { type: Boolean, default: false },
    mobility: { type: Boolean, default: false },
    wash: { type: Boolean, default: false },
    schedule: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import "/Sidebar";
</style>
