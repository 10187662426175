<template>
  <footer class="footer row">
    <slot></slot>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
@import "/Footer";
</style>
