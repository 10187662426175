<template>
  <div :class="justMobileClass + 'car-info'">
    <img class="car-image" :src="carImage" alt="car image"/>
    <p class="title">{{ carPlate }}</p>
    <p class="text">{{ carName }}</p>
  </div>
</template>

<script>
export default {
  name: "CarInfo",
  props: {
    justMobile: { type: Boolean, default: false },
  },
  data() {
    return {
      carImage: this.$store.state.carImage
        ? this.$store.state.carImage
        : require("@/assets/car_placeholder.png"),
      carName: this.$store.state.carName
        ? this.$store.state.carName
        : this.$store.state.carNF.brand + " " + this.$store.state.carNF.model,
      carPlate: this.$store.state.carPlate,
      justMobileClass: this.justMobile ? "elm-mobile " : "",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/UpdatedResume";
</style>
